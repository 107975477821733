import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MapCesiumComponent} from './map-cesium.component';
import {BACKGROUND_DEFAULT, BACKGROUND_PROVIDERS} from './custom-map-conf';

@Component({
  selector: 'select-map-background',
  template: `
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Provider</mat-label>
      <mat-select [(ngModel)]="selectedBackground" (ngModelChange)="pickBackground(selectedBackground)">
        <mat-option *ngFor="let background of BACKGROUND_PROVIDERS" [value]="background.alias">
          {{background.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `
})
export class SelectMapBackgroundComponent implements OnChanges {

  @Input('map') map: MapCesiumComponent;

  protected readonly BACKGROUND_PROVIDERS = BACKGROUND_PROVIDERS;

  public selectedBackground = BACKGROUND_DEFAULT;

  public pickBackground(value: string): void {
    if (!this.map?.viewer) {
      console.error('[SelectMapBackgroundComponent] -> pickBackground(arguments) -> viewer not found');
      return;
    }

    const provider = this.BACKGROUND_PROVIDERS.find(p => p.alias === value);
    const viewer = this.map.viewer;
    const imageryLayers = viewer.imageryLayers;

    const baseLayer = imageryLayers.get(0);

    if (baseLayer) {
      imageryLayers.remove(baseLayer, false);
    }

    const newBaseLayer = imageryLayers.addImageryProvider(provider.value, 0);

    this.map.background = value;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.map && changes.map.currentValue) {
      this.selectedBackground = this.map.background;
    }
  }

  constructor() {}
}
