import ImageryProvider from 'cesium/Source/Scene/ImageryProvider';

/**
 * surchage de la configuration de la carte par type_zone (code pays)
 */
export const CUSTOM_MAP_CONF: {
  [key: string]: { // type zone
    backgroundAlias: string; // Alias du provider de fond de carte
    layers_out: string[]; // Liste des layers(par alias) à ne pas afficher
  }
} = {
  CIV: {
    backgroundAlias: 'arcgis',
    layers_out: [
      'zmi',
      'zms',
      'road',
      'railway',
      'hydroSurface',
      'watercourse',
      'places'
    ]
  },
  default: {
    backgroundAlias: 'ign-sat',
    layers_out: ['ziparu']
  }
};

export const BACKGROUND_PROVIDERS = [
  {
    value: new Cesium.WebMapTileServiceImageryProvider({
      url : 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile',
      style : 'normal',
      format : 'image/jpeg',
    }),
    label: 'Arcgis',
    alias: 'arcgis'
  }, {
    value: new Cesium.OpenStreetMapImageryProvider({
      url: 'https://tile.openstreetmap.org/'
    }),
    label: 'OpenStreetMap',
    alias: 'osm'
  }, {
    value: new Cesium.WebMapTileServiceImageryProvider({
      url: 'https://data.geopf.fr/wmts',
      layer: 'ORTHOIMAGERY.ORTHOPHOTOS',
      style: 'normal',
      format: 'image/jpeg',
      tileMatrixSetID: 'PM',
      tileMatrixLabels: Array.from({ length: 20 }, (_, i) => i.toString()),
      maximumLevel: 19,
    }),
    label: 'IGN satellite',
    alias: 'ign-sat'
  }
];

export const BACKGROUND_DEFAULT = 'ign-sat';

export const manageLayerByZone = (viewier, layers, type_zone) => {
  let customConf = CUSTOM_MAP_CONF[type_zone];

  if (!customConf) {
    customConf = CUSTOM_MAP_CONF.default;
  }

  customConf.layers_out.forEach((layerOutAlias) => {
    const layerIdx = layers.findIndex((layer) => layer.alias === layerOutAlias);

    if (layerIdx === -1) {
      return;
    }

    layers[layerIdx].layer.show = false;

    if (layers[layerIdx].layer.entities) {
      viewier.dataSources.remove(layers[layerIdx].layer);
    }

    if (layers[layerIdx].layer.imageryLayers) {
      viewier.imageryLayers.remove(layers[layerIdx].layer);
    }
  });

  return layers.filter((layer) => !customConf.layers_out.includes(layer.alias));
};
