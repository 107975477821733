import {Viewer} from 'cesium';
import * as Cesium from 'cesium';
import {getVisiblePoints} from '../tools';
import {Subscription} from 'rxjs';
import {MapperViewer} from './new-map-cesium.components';
import {ElementRef} from '@angular/core';
import { BBox } from './types';

export const canvasSize = ({width, height}, viewer: Viewer): void => {
  viewer.canvas.width = width;
  viewer.canvas.height = height;
  viewer.canvas.style.width = width + 'px';
  viewer.canvas.style.height = height + 'px';
  viewer.canvas.style.transform = `scale(${document.body.clientHeight * 0.80 / height})`;
};

export const setMapRenderMode = (mode: '2d' | '3d', viewer: Viewer): void =>  {
  if (mode === '2d') {
    viewer.scene.morphTo2D(0);
    setMapCanvasSize({
      ratio: 1.357,
      size: document.body.clientHeight * 0.80,
      viewer
    });
  } else {
    viewer.scene.morphTo3D(0);
    setMapCanvasSize({
      ratio: 1,
      size: document.body.clientHeight * 0.80,
      viewer
    });
  }

  viewer.render();
};

export const setMapCanvasSize = ({ratio, size, viewer}: {
  ratio: number,
  size: number,
  viewer: Viewer
}): void => {
  canvasSize({width: size * ratio, height: size}, viewer);
};


export const normalizeMultipolygon = (array): any[] =>  {
  return array.map(a => {
    return a.map(b => b.map(c => ([c[0], c[1]])));
  });
};


export const optimizePolygon = (points, outputMaxPointCount = 100): Cesium.Cartesian3[] => {
  if (points.length < 100) {
    return Cesium.Cartesian3.fromDegreesArray(points.flat());
  }

  const polygonOpti: any = [];
  let a = 0;
  for (let i = 0; i < points.length; i ++) {
    a++;
    if (a === Math.round(points.length / outputMaxPointCount)) {
      a = 0;
      polygonOpti.push(points[i]);
    }
  }

  return Cesium.Cartesian3.fromDegreesArray(polygonOpti.flat());
};

export const multiPolygonToCartesianPolygonHierarchies = (arr): Cesium.Cartesian3[] => {
  const deepCopy = JSON.parse(JSON.stringify(arr));
  const result = [];

  deepCopy.forEach(a => {
    a.forEach(b => {
      result.push({positions: b.map(c => Cesium.Cartesian3.fromDegreesArray(c)).flat()});
    });
  });

  return result;
};

export const distanceVisibleInCamera = (viewer: Viewer): number => {
  const {west, north, east, south} = getCameraBoundingBox(viewer);

  const startCartesian3Point = Cesium.Cartesian3.fromDegrees(north, west);
  const endCartesian3Point = Cesium.Cartesian3.fromDegrees(south, east);

  const startCartographicPoint = Cesium.Cartographic.fromCartesian(startCartesian3Point);
  const endCartographicPoint = Cesium.Cartographic.fromCartesian(endCartesian3Point);

  const ellipsoidGeodesic = new Cesium.EllipsoidGeodesic(startCartographicPoint,
    endCartographicPoint );
  const distance = ellipsoidGeodesic.surfaceDistance;

  return distance * 0.001;
};

export const getCameraBoundingBox = (viewer: Viewer): any => {
  const scene = viewer.scene;
  const camera = scene.camera;
  const canvas = scene.canvas;
  const ellipsoid = scene.globe.ellipsoid;

  const corners = [
    new Cesium.Cartesian2(0, 0),
    new Cesium.Cartesian2(canvas.width, 0),
    new Cesium.Cartesian2(0, canvas.height),
    new Cesium.Cartesian2(canvas.width, canvas.height)
  ];

  let west = Infinity;
  let east = -Infinity;
  let south = Infinity;
  let north = -Infinity;

  corners.forEach(corner => {
    const ray = camera.getPickRay(corner);
    if (ray) {
      const intersection = scene.globe.pick(ray, scene);
      if (intersection) {
        const cartographic = ellipsoid.cartesianToCartographic(intersection);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);

        west = Math.min(west, longitude);
        east = Math.max(east, longitude);
        south = Math.min(south, latitude);
        north = Math.max(north, latitude);
      }
    }
  });

  if (west === Infinity || east === -Infinity || south === Infinity || north === -Infinity) {
    return {
      west: 0,
      north: 0,
      east: 0,
      south: 0,
    };
  }

  // Gestion du cas où la bbox traverse le méridien 180°
  if (west > east) {
    if (Math.abs(west) > Math.abs(east)) {
      west -= 360;
    } else {
      east += 360;
    }
  }

  return {
    west: +west.toFixed(4),
    north: +north.toFixed(4),
    east: +east.toFixed(4),
    south: +south.toFixed(4),
  };
};

export const toBBoxString = (rectangleCords): any => {
  return rectangleCords.west + ',' + rectangleCords.north + ',' + rectangleCords.east + ',' + rectangleCords.south;
};

export const toIGNBBoxString = (rectangleCords): any => {
  return `${rectangleCords.south},${rectangleCords.west},${rectangleCords.north},${rectangleCords.east}`
};

export const createNode = (n, v): any => {
  n = document.createElementNS("http://www.w3.org/2000/svg", n);
  for (var p in v)
    n.setAttribute(p, v[p]);
  return n
};

export const drawSvg = (num, priority): any => {
  return new Promise((resolve) => {
    const svg = createNode('svg', {
      "width": 100,
      "height": 100,
      viewBox: '0 0 100 100',
      xmlns: "http://www.w3.org/2000/svg"
    });
    let color = '#000';
    if (priority === 'Elevée') {
      color = '#f1c40f' ;
    }

    const circle = createNode('circle', {
      cy: '50',
      cx: '50',
      r: '50',
      fill: color,
    });

    let colorText = '#FFF';
    if (priority === 'Elevée') {
      colorText = '#000';
    }

    const number = createNode('text', {
      "font-weight": "bold",
      "text-anchor": "start",
      "font-family": "Roboto, Helvetica, Arial, sans-serif",
      "font-size": "50",
      "y": "65",
      "x": num.length > 2 ? '5' : num.length > 1 ? '23' : '35',
      "fill": colorText,
    });

    number.innerHTML = num;

    svg.append(circle);
    svg.append(number);

    const outerHTML = svg.outerHTML;
    const blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      const img: any = new Image();
      img.src = base64data;

      resolve(img);
    };
  });
}


export const buildOrDivide = (res, bbox, url, buildFn): any => {

  if (res.numberMatched >= 1000) {

    const cleanedBbox = typeof bbox === 'string' ? bbox.split(',').map(a => Number(a)) : bbox;
    const bboxArray = divideBbox(cleanedBbox,
      Math.sqrt(res.numberMatched / 1000) * 3);

    bboxArray.forEach((_bbox) => {
      fetch(url + _bbox.join(',')).then(raw => raw.json()).then(_res => {
        // buildOrDivide(_res, _bbox, url, buildFn);
      });
    });
  } else {
    buildFn(res);
  }
};

export const divideBbox = (bbox, nCut): any => {
  console.log('[] -> divideBbox(list) -> ', nCut)
  const pasX = (bbox[2] - bbox[0]) / nCut;
  const pasY = (bbox[3] - bbox[1]) / nCut;
  const list = [];

  for (let xInc = 0; xInc < nCut; xInc++) {
    for (let yInc = 0; yInc < nCut; yInc++) {
      const xMin = bbox[0] + pasX * xInc;
      const yMin = bbox[1] + pasY * yInc;
      const xMax = xMin + pasX;
      const yMax = yMin + pasY;
      list.push([xMin, yMin, xMax, yMax]);
    }
  }

  return list;
}


export const screenshot = (
  viewer: MapperViewer,
  canvasSnapshotRef: ElementRef,
  echelleFixedValue,
  event?: Subscription): void => {

  const baseScreenshot = viewer.canvas.toDataURL('image/jpeg', 1);

  // download(baseScreenshot);

  ////// SCREENSHOT
  // create a canvas

  const ctx: CanvasRenderingContext2D = canvasSnapshotRef.nativeElement.getContext('2d');

  console.log('[] -> screenshot(ctx) -> ', ctx)

  ctx.clearRect(0, 0, canvasSnapshotRef.nativeElement.width, canvasSnapshotRef.nativeElement.height);



  const img = document.createElement('img');
  img.src = baseScreenshot;
  img.addEventListener('load', () => {
    /**
     * The actual map screenshot
     */
    ctx.drawImage(img, 0, 0);
    /**
     * The scale
     */
    const originLeft = 1500 - (echelleFixedValue.barWidthComputed + 150);

    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 0;

    // background
    ctx.beginPath();
    ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
    ctx.rect(originLeft, 1500 - 50, echelleFixedValue.barWidthComputed + 150, 50);
    ctx.fill();
    ctx.closePath();

    // label
    ctx.fillStyle = 'black';
    ctx.textAlign = 'start';
    ctx.font = '30px Arial';
    ctx.fillText(echelleFixedValue.distanceLabel, originLeft + echelleFixedValue.barWidthComputed + 50, 1500 - 15);

    //// BAR@
    ctx.beginPath();
    ctx.fillStyle = 'black';
    //left
    ctx.rect(originLeft + 30, 1500 - 29, 3, 15);
    ctx.fill();
    //bottom
    ctx.rect(originLeft + 30, 1500 - 15, echelleFixedValue.barWidthComputed, 3);
    ctx.fill();
    //right
    ctx.rect(originLeft + 27 + echelleFixedValue.barWidthComputed, 1500 - 30, 3, 15);
    ctx.fill();
    ctx.closePath();


    /**
     * The arrow
     */
    const arrow = document.createElement('img');
    arrow.src = '../../assets/images/north-arrow.png';
    arrow.addEventListener('load', () => {
      const imgSize = 100;

      ctx.save();
      ctx.translate(1400 + imgSize / 2,  imgSize / 2);
      ctx.rotate(-viewer.camera.heading);
      ctx.drawImage(arrow, imgSize / 2 * (-1), imgSize / 2 * (-1), imgSize, imgSize);
      ctx.restore();


      const finalScreenshot = viewer.canvas.toDataURL('image/jpeg', 1);

      download(finalScreenshot);


      console.log('[] -> (finalScreenshot) -> ', finalScreenshot)
      // const blob = this.downloadService.dataURItoBlob(finalScreenshot);
    });
  });
};

export const download = (href: string): void => {
  const link = document.createElement('a');
  link.download = 'screenshot.jpg';
  link.href = href;
  link.click();
};


export const isBBoxInRadians = (bbox: BBox): boolean => {
  const all = [bbox.west, bbox.south, bbox.east, bbox.north];
  return all.every(v => Math.abs(v) <= Math.PI);
};

export const toDegreesBBox = (bbox: BBox): BBox => {
  return {
    west: Cesium.Math.toDegrees(bbox.west),
    south: Cesium.Math.toDegrees(bbox.south),
    east: Cesium.Math.toDegrees(bbox.east),
    north: Cesium.Math.toDegrees(bbox.north),
  };
};

export const normalizeBBoxToDegrees = (bbox: BBox): BBox => {
  return isBBoxInRadians(bbox) ? toDegreesBBox(bbox) : bbox;
};
