import {CustomDataSource, ImageryLayer, Viewer} from 'cesium';
import {MapperViewer} from './new-map-cesium.components';

export enum LayerLoadType {
  MoveEnd = 'moveEnd',
  Instant = 'instant',
  Manual = 'manual'
}
export enum LayerType {
  DataSource = 'dataSource',
  Imagery = 'imagery'
}

export interface Layer {
  id: string;
  name: string;
  active: boolean;
  loadType: LayerLoadType;
  viewer: MapperViewer;

  initialize(viewer: MapperViewer): void;
  loadData(): void;
  remove(): void;
  setActive(active: boolean): void;
  onBeforePrint(): void;
  onAfterPrint(): void;
}

export class LayerDatasource implements Layer {
  dataSource: CustomDataSource;
  active: boolean;
  id: string;
  loadType: LayerLoadType;
  name: string;
  viewer: MapperViewer;
  showLabel: boolean;

  initialize(viewer: MapperViewer): void {}

  loadData(): void {}

  remove(): void {}

  setActive(active: boolean): void {}

  onBeforePrint(): void {}

  onAfterPrint(): void {}
}

export class LayerImagery implements Layer {
  imageryLayer: ImageryLayer;
  active: boolean;
  id: string;
  loadType: LayerLoadType;
  name: string;
  viewer: MapperViewer;

  initialize(viewer: MapperViewer): void {}

  loadData(): void {}

  remove(): void {}

  setActive(active: boolean): void {}

  onBeforePrint(): void {}

  onAfterPrint(): void {}
}


export type BBox = {
  west: number;
  south: number;
  east: number;
  north: number;
};
