import {Component, OnDestroy, OnInit} from '@angular/core';
import {Layer, LayerLoadType} from '../types';
import {LayerService} from '../../../services/layer.service';


@Component({
    selector: 'layer-control',
    templateUrl: './layer-control.component.html',
})
export class LayerControlComponent implements OnInit, OnDestroy {

  constructor(
    private layerService: LayerService
  ) {}

  dynamicLayers = [];
  subscription: any;

  protected readonly LayerLoadType = LayerLoadType;

  public handleLayerVisibility(layer: Layer): void {
    layer.setActive(!layer.active);
  }

  ngOnInit(): void {
    this.subscription = this.layerService.layers$.subscribe(layers => {
      this.dynamicLayers = layers;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
