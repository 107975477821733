import * as Cesium from 'cesium';
import {MapperViewer} from '../../new-map-cesium.components';
import {FeatureCollection} from '@turf/turf';
import {getCameraBoundingBox, toIGNBBoxString} from '../../cesiumTools';
import {LayerDatasource, LayerLoadType} from '../../types';

export class RoadCIVLayer implements LayerDatasource {

  id = 'road_civ';
  name = 'Routes';
  active = true;
  dataSource = new Cesium.GeoJsonDataSource(this.name);
  loadType = LayerLoadType.Manual;
  viewer: MapperViewer;
  showLabel = false;

  /* Custom properties */
  url = 'https://mapserver-v2.predictservices.ws/wfs?\n' +
    'SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&TYPENAMES=ms:civ_route_decoupe_wfs&STARTINDEX=0&COUNT\n' +
    '=10000&SRSNAME=urn:ogc:def:crs:EPSG::4326&OUTPUTFORMAT=geojson&BBOX=';

  initialize(viewer: MapperViewer): void {
    this.viewer = viewer;
    viewer.dataSources.add(this.dataSource);
  }

  remove(): void {
    this.viewer.dataSources.remove(this.dataSource);
  }

  loadData(): void {
    if (!this.dataSource) {
      console.error('Viewer not initialized');
      return;
    }

    const bbox = toIGNBBoxString(getCameraBoundingBox(this.viewer));

    if (bbox) {
      const url = `${this.url}${bbox}`;
      fetch(url)
        .then(res => res.json())
        .then((res: FeatureCollection) => {
          this.buildRoad(res);
        });
    }
  }

  getStyle(type): any {
    switch (type) {
      case 'Route secondaire':
      case 'Route tertiaire':
        return {
          width: 6,
          color: '#FFA500',
        };
      case 'Route primaire':
      case 'Voie rapide':
        return {
          width: 9,
          color: '#FFA500',
        };
    }

    return null;
  }

  buildRoad(geojson: FeatureCollection): void {
    this.dataSource.load(geojson, {
      clampToGround: true,
    });

    this.dataSource.entities.values.forEach(road => {
      const style = this.getStyle(road.properties.NATURE?.getValue());

      if (road.polyline && style) {
        road.polyline.material = new Cesium.PolylineOutlineMaterialProperty({
          color: Cesium.Color.fromCssColorString(style.color),
        });
        road.polyline.width = new Cesium.ConstantProperty(style.width);
      }
    });
  }

  setActive(active: boolean): void {
    this.active = active;
    this.dataSource.show = active;
  }

  onAfterPrint(): void {
  }

  onBeforePrint(): void {
  }
}
