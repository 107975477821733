import {Cartesian3, Color, SceneMode} from 'cesium';
import {MapperViewer} from '../../new-map-cesium.components';
import {LayerDatasource, LayerLoadType} from '../../types';
import {capitalize} from '../../../tools';
import {getCameraBoundingBox, toIGNBBoxString} from '../../cesiumTools';
import {FeatureCollection} from '@turf/turf';

export class PlacesCIVLayer implements LayerDatasource {

  id = 'places_civ';
  name = 'Toponymes';
  active = true;
  dataSource = new Cesium.CustomDataSource(this.name);
  loadType = LayerLoadType.Manual;
  viewer: MapperViewer;
  showLabel = true;

  /* Custom properties */
  url = 'https://mapserver-v2.predictservices.ws/wfs?\n' +
    'SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&TYPENAMES=ms:civ_LIEU_DIT_HABITE_decoupe_V2_wfs&STARTI\n' +
    'NDEX=0&COUNT=10000&SRSNAME=urn:ogc:def:crs:EPSG::4326&OUTPUTFORMAT=geojson&BBOX='

  hack = [];

  initialize(viewer: MapperViewer): void {
    this.viewer = viewer;
    viewer.dataSources.add(this.dataSource);
  }

  remove(): void {
    this.viewer.dataSources.remove(this.dataSource);
  }

  loadData(): void {
    if (!this.dataSource) {
      console.error('Viewer not initialized');
      return;
    }

    const bbox = toIGNBBoxString(getCameraBoundingBox(this.viewer));

    if (bbox) {
      const url = `${this.url}${bbox}`;
      fetch(url)
        .then(res => res.json())
        .then((res: FeatureCollection) => {
          this.buildPlaces(res);
        });
    }
  }

  buildPlaces(geojson: any): void {
    if (geojson?.features.length) {
      geojson.features.forEach((place) => {
        const id = `place_${place.properties.NOM}`;
        if (!this.dataSource.entities.getById(id)) {
          this.addLabel(id, Cesium.Cartesian3.fromDegrees(
            place.geometry.coordinates[0],
            place.geometry.coordinates[1]
          ), capitalize(place.properties.NOM));
        }
      });
    }
  }

  addLabel(id: string, position: Cartesian3, text: string): void {
    const textStyle = this.viewer.scene.mode === SceneMode.SCENE2D ? {
      font: `2px sans-serif`,
      backgroundPadding: new Cesium.Cartesian2(2, 1),
    } : {
      font: `7px sans-serif`,
      backgroundPadding: new Cesium.Cartesian2(3, 2),
    };

    this.dataSource.entities.add({
      id,
      position,
      label: {
        text,
        disableDepthTestDistance: 2000,
        fillColor: Cesium.Color.GREEN,
        backgroundColor: Cesium.Color.WHITE,
        showBackground: true,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        eyeOffset:  new Cesium.Cartesian3( 0, 0, -200 ),
        ...textStyle
      }
    });
  }

  setActive(active: boolean): void {
    this.active = active;
    this.dataSource.show = active;
  }

  onAfterPrint(): void {
  }

  onBeforePrint(): void {
  }
}
