import {SceneMode} from 'cesium';
import {MapperViewer} from '../../new-map-cesium.components';
import * as turf from '@turf/turf';
import {FeatureCollection} from '@turf/turf';
import {getCameraBoundingBox, toIGNBBoxString} from '../../cesiumTools';
import {LayerDatasource, LayerLoadType} from '../../types';

export class WatercourseCIVLayer implements LayerDatasource {

  id = 'watercourse_civ';
  name = 'Cours d\'eau';
  active = true;
  dataSource = new Cesium.GeoJsonDataSource(this.name);
  loadType = LayerLoadType.Manual;
  viewer: MapperViewer;
  showLabel = false;

  /* Custom properties */
  url = 'https://mapserver-v2.predictservices.ws/wfs?\n' +
    'SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&TYPENAMES=ms:civ_cours_d_eau_decoupe_wfs&STARTINDEX=0&C\n' +
    'OUNT=10000&SRSNAME=urn:ogc:def:crs:EPSG::4326&OUTPUTFORMAT=geojson&BBOX=';

  labelDistance = 1500;

  initialize(viewer: MapperViewer): void {
    this.viewer = viewer;
    viewer.dataSources.add(this.dataSource);

    this.viewer.scene.canvas.addEventListener('webglcontextlost', (event) => {
      console.warn('Contexte WebGL perdu. Tentative de réinitialisation...');
      event.preventDefault();
      this.viewer.scene.requestRender();
    });
  }

  remove(): void {
    this.viewer.dataSources.remove(this.dataSource);
  }

  loadData(): void {
    if (!this.dataSource) {
      console.error('Viewer not initialized');
      return;
    }

    const bbox = toIGNBBoxString(getCameraBoundingBox(this.viewer));

    if (bbox) {
      const url = `${this.url}${bbox}`;
      fetch(url)
        .then(res => res.json())
        .then((res: FeatureCollection) => {
          this.buildRoad(res);
        });
    }
  }

  buildRoad(geojson: FeatureCollection): void {
    this.dataSource.load(geojson, {
      clampToGround: true,
    });

    this.dataSource.entities.values.forEach(road => {
      if (road.polyline) {
        const polyline = road.polyline;
        polyline.material = new Cesium.ColorMaterialProperty(Cesium.Color.ROYALBLUE);
        polyline.width = new Cesium.ConstantProperty(4);
        polyline.arcType = new Cesium.ConstantProperty(Cesium.ArcType.RHUMB);
      }
    });

    const watercourseMap = new Map();
    const radius = this.labelDistance;

    const textStyle = this.viewer.scene.mode === SceneMode.SCENE2D ? {
      font: `2px sans-serif`,
      backgroundPadding: new Cesium.Cartesian2(2, 1),
    } : {
      font: `17px sans-serif`,
      backgroundPadding: new Cesium.Cartesian2(3, 2),
    };

    geojson?.features?.forEach((watercourse: any) => {

      if (watercourse.geometry.type !== 'LineString') {
        return;
      }

      // On ne prend pas en compte la hauteur (z) des coordonnées
      watercourse.geometry.coordinates = watercourse.geometry.coordinates.map(a => a.slice(0, 2));

      const coordinates = watercourse.geometry.coordinates;
      const watercourseName = watercourse.properties.NOM;

      if (watercourseName !== 'NC') {
        // regroupement des coordonnées (LineString) par nom de cours d'eau pour l'affichage des labels
        if (watercourseMap.has(watercourseName)) {
          watercourseMap.get(watercourseName).push(coordinates);
        } else {
          // On ne prend pas en compte les cours d'eau sans nom
          if (watercourseName) {
            watercourseMap.set(watercourseName, [coordinates]);
          }
        }
      }
    });

    watercourseMap.forEach((lineStringArray, watercourseName) => {
      const filteredLatLngList = lineStringArray
        .flat() // on prend les coordonnées de chaque LineString
        .sort((a, b) => a[0] - b[0]) // on trie par longitude
        .reduce((acc, item) => { // on garde uniquement les points qui sont à plus de (x) mètres de distance
          if (acc.length === 0) {
            acc.push(item);
          } else if (acc.every((a) => turf.rhumbDistance(a, item, {units: 'meters'}) > radius)) {
            acc.push(item);
          }
          return acc;
        }, []);

      // Render des labels
      filteredLatLngList.forEach((coord: number[]) => {
        this.dataSource.entities.add({
          position: Cesium.Cartesian3.fromDegrees(coord[0], coord[1]),
          label: {
            text: watercourseName,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            fillColor: Cesium.Color.ROYALBLUE,
            showBackground: true,
            backgroundColor: Cesium.Color.WHITE,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            ...textStyle
          }
        });
      });
    });
  }

  setActive(active: boolean): void {
    this.active = active;
    this.dataSource.show = active;
  }

  setLabelDistance(distance: number): void {
    this.labelDistance = distance;
  }

  setLabelVisibility(visible: boolean): void {
    this.showLabel = visible;
    this.dataSource.entities.values.forEach((entity) => {
      if (entity.label) {
        entity.label.show = visible;
      }
    });
  }

  onAfterPrint(): void {
  }

  onBeforePrint(): void {
  }
}
