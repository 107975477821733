import { Injectable } from '@angular/core';
import { Viewer, Camera } from 'cesium';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CesiumEventService {
  private viewer: Viewer;
  private cameraMoveEndSubject = new Subject<void>();
  cameraMoveEnd$ = this.cameraMoveEndSubject.asObservable();

  initialize(viewer: Viewer) {
    this.viewer = viewer;
    this.setupCameraMoveEndListener();
  }

  private setupCameraMoveEndListener() {
    this.viewer.camera.moveEnd.addEventListener(() => {
      this.cameraMoveEndSubject.next();
    });
  }
}
