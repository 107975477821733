import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {A0, SnapshotType} from '../../../shared/entities/A0.entity';
import {MapperService} from '../../../services/mapper.service';
import {MapCesiumComponent} from '../../../shared/cesium/map-cesium.component';
import {zip} from 'rxjs';
import {CdkDragEnd} from '@angular/cdk/drag-drop';
import {convertDimensionWithRatio} from '../../../shared/tools';
import {manageLayerByZone} from '../../../shared/cesium/custom-map-conf';
import { LayerLoadType } from 'src/shared/cesium/types';
import {NewMapCesiumComponent} from '../../../shared/cesium/new-map-cesium.components';

@Component({
  selector: 'app-plan-map-3d',
  templateUrl: './map-3d.component.html',
  styleUrls: ['./map-3d.component.scss']
})

export class Map3dComponent implements AfterViewInit {

  plan: A0;
  listSnapshot = [];
  currentSnapshot: any = {
    type: ''
  };

  titleInitialPosition = {x:0, y:0};

  _type = '';

  manageableLayers = [];
  isLoadingLayer = false;

  layersToLoad = [
    'building', 'road', 'hydroSurface', 'watercourse', 'railway'
  ];

  allLayersToLoad = [
    { alias: 'building', name: 'Batiments', selected: true},
    { alias: 'road', name: 'Routes', selected: true},
    { alias: 'railway', name: 'Voies ferrées', selected: true},
    { alias: 'hydroSurface', name: `Surfaces en eau`, selected: true},
    { alias: 'watercourse', name: `Cours d'eau`, selected: true},
    { alias: 'places', name: 'Toponymes', selected: false},
  ];

  @ViewChild('map') mapComponent: MapCesiumComponent;
  @ViewChild('newMap') newMapComponent: NewMapCesiumComponent;

  constructor(
    public route: ActivatedRoute,
    public mapperService: MapperService
  ) {}

  ngAfterViewInit(): void {
    this.getPlan(this.route.snapshot.paramMap.get('id'));

    this._type = this.route.snapshot.paramMap.get('type') as SnapshotType;
    this.currentSnapshot.type = this._type;
  }

  handleVisibility(item): void {
    item.layer.show = !item.layer.show;

    if (item.name === 'PCS') {
      item.layer.num.show = item.layer.show;
    }
  }

  updatePCSpictoSize(event, item): any {
    item.layer.buildPCS(event.value);
  }

  public getPlan(id): void {
    zip(this.mapperService.getSnapshotList(id),
      this.mapperService.getPlan(id)).subscribe(([listSnapshot, plan]) => {
      const _zoom = listSnapshot.filter(snapshot => snapshot.id_zoom.includes('ZOOM'));
      this.plan = plan;
      this.listSnapshot = _zoom;

      this.currentSnapshot = {...this.currentSnapshot, ...this.listSnapshot.find(a => a.id_zoom === this._type)};
    });
  }

  loadAllLayer(): void {
    this.mapComponent.loadAllLayer(this.layersToLoad);
  }

  screenshot(): void {
    this.mapComponent.take3dScreenshot(this.currentSnapshot);
  }

  mapIsInit(): void {
    this.mapComponent.handleContextMenu();

    setTimeout(() => {
      if (this.currentSnapshot.title_position) {
        this.titleInitialPosition = convertDimensionWithRatio(
          [1500, 1500], this.getContainerDimension(),
          this.currentSnapshot.title_position
        );
      }
    })

    this.manageableLayers = manageLayerByZone(this.mapComponent.viewer, [
      {name: 'Limite communale', layer: this.mapComponent.polygon, alias: 'polygon'},
      {name: 'ZMI', layer: this.mapComponent.zmi, alias: 'zmi'},
      {name: 'ZMS', layer: this.mapComponent.zms, alias: 'zms'},
      {name: 'ZI PARU', layer: this.mapComponent.ziparu , alias: 'ziparu'},
      {name: 'PCS', layer: this.mapComponent.picto, alias: 'picto', collapsed: false},
      {name: 'Routes', layer: this.mapComponent.road, alias: 'road'},
      {name: 'voies ferrées', layer: this.mapComponent.railway, alias: 'railway'},
      {name: 'Surfaces en eau', layer: this.mapComponent.hydroSurface, alias: 'hydroSurface'},
      {name: 'Cours d’eau', layer: this.mapComponent.watercourse, alias: 'watercourse'},
      {name: 'Toponymes', layer: this.mapComponent.places, alias: 'places'},
    ], this.plan.type_zone);
  }

  domToCanvasPosition(item): any {
    const [elX, elY] = this.getItemCanvasPosition(item);

    const canvasWidth = 1500;
    const canvasHeight = 1500;

    const [containerWidth, containerHeight] = this.getContainerDimension();

    return `${Math.round(elX * (canvasWidth / containerWidth))},${Math.round(elY * (canvasHeight / containerHeight))}`
  }

  dragEnd(event: CdkDragEnd): void {
    const item = event.source.element.nativeElement;
    const positionType = event.source.element.nativeElement.id;
    this.currentSnapshot[positionType] = this.domToCanvasPosition(item);
  }

  getItemCanvasPosition(nativeElement): any {
    const transform = nativeElement.style.transform;
    return transform.match(/[0-9]*px/gm).map(a => a.replace('px', ''));
  }

  getContainerDimension(): any {
    return [
      Number(this.mapComponent.map._lastHeight),
      Number(this.mapComponent.map._lastWidth)
    ];
  }

  handleNiveauxVisibility(item): void {
    item.layer.showNiveau = !item.layer.showNiveau;
    if (item.name === 'ZMI') {
      this.mapComponent.previouslyLoadedZmi = [];
      this.mapComponent.buildZmi();
    } else if (item.name === 'ZMS') {
      this.mapComponent.previouslyLoadedZms = [];
      this.mapComponent.buildZms();
    }
  }

  protected readonly LayerLoadType = LayerLoadType;
}
