import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ZmiLayer} from '../shared/cesium/layers/zmi.layer';
import {ZoneLayer} from '../shared/cesium/layers/zone.layer';
import {PredictService} from './predict.service';
import {IgoService} from './igo.service';
import {HighwayCIVLayer} from '../shared/cesium/layers/CIV/higway_civ.layer';
import {RoadCIVLayer} from '../shared/cesium/layers/CIV/road_civ.layer';
import {RailwayCIVLayer} from '../shared/cesium/layers/CIV/railway_civ.layer';
import {Layer, LayerLoadType} from '../shared/cesium/types';
import {MapperViewer} from '../shared/cesium/new-map-cesium.components';
import {RoadLayer} from '../shared/cesium/layers/default/road.layer';
import {PcsLayer} from '../shared/cesium/layers/default/pcs.layer';
import {PlanLayer} from '../shared/cesium/layers/default/plan.layer';
import {MapperService} from './mapper.service';
import {WatercourseCIVLayer} from '../shared/cesium/layers/CIV/watercourse_civ.layer';
import {PlacesCIVLayer} from '../shared/cesium/layers/CIV/places_civ.layer';

@Injectable({ providedIn: 'root' })
export class LayerService {
  private viewer: MapperViewer;
  private layers: Layer[] = [];
  private layersSubject = new BehaviorSubject<Layer[]>([]);
  layers$ = this.layersSubject.asObservable();

  constructor(
    public predictService: PredictService,
    public igoService: IgoService,
    public mapperService: MapperService
  ) {
  }

  initialize(viewer: MapperViewer): void {
    this.viewer = viewer;

    /* Generic layers */
    // this.addLayer(new ZoneLayer(this.predictService));

    /* Type of plan */
    if (this.viewer.plan.id_user) {
      // this.addLayer(new PlanLayer(this.mapperService));
    } else {
      // this.addLayer(new PcsLayer(this.igoService));
    }

    /* Custom layers */
    switch (viewer.plan.type_zone) {
      case 'CIV':
        this.addLayer(new HighwayCIVLayer());
        this.addLayer(new RoadCIVLayer());
        this.addLayer(new RailwayCIVLayer());
        this.addLayer(new WatercourseCIVLayer());
        this.addLayer(new PlacesCIVLayer());
        break;

      // default:
      //   this.addLayer(new ZmiLayer(this.predictService));
      //   this.addLayer(new RoadLayer());
      //   this.addLayer(new PcsLayer(this.igoService));
      //   break;
    }
  }

  addLayer(layer: Layer): void {
    layer.initialize(this.viewer);

    if (layer.loadType === LayerLoadType.Instant) {
      layer.loadData();
    }

    this.layers.push(layer);
    this.layersSubject.next(this.layers);
  }

  getLayer(id: string): Layer | undefined {
    return this.layers.find(l => l.id === id);
  }

  getActiveLayers(): Layer[] {
    return this.layers.filter(l => l.active);
  }

  clearLayers(): void {
    this.layers.forEach((layer: Layer) => {
      layer.remove();
    });

    this.layers = [];

    this.layersSubject.next(this.layers);
  }
}
