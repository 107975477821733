import * as Cesium from 'cesium';
import {MapperViewer} from '../../new-map-cesium.components';
import {FeatureCollection} from '@turf/turf';
import {getCameraBoundingBox, toIGNBBoxString} from '../../cesiumTools';
import {LayerDatasource, LayerLoadType, LayerType} from '../../types';

export class HighwayCIVLayer implements LayerDatasource {

  id = 'higway_civ';
  name = 'Autoroutes';
  active = true;
  dataSource = new Cesium.GeoJsonDataSource(this.name);
  loadType = LayerLoadType.Manual;
  viewer: MapperViewer;
  showLabel = false;

  /* Custom properties */
  url = 'https://mapserver-v2.predictservices.ws/wfs?\n' +
    'SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&TYPENAMES=ms:civ_autoroute_decoupe_wfs&STARTINDEX=0&COU\n' +
    'NT=10000&SRSNAME=urn:ogc:def:crs:EPSG::4326&OUTPUTFORMAT=geojson&BBOX=';

  constructor() {}

  initialize(viewer: MapperViewer): void {
    this.viewer = viewer;
    viewer.dataSources.add(this.dataSource);
  }

  remove(): void {
    this.viewer.dataSources.remove(this.dataSource);
  }

  loadData(): void {
    if (!this.dataSource) {
      console.error('Viewer not initialized');
      return;
    }

    const bbox = toIGNBBoxString(getCameraBoundingBox(this.viewer));

    if (bbox) {
      const url = `${this.url}${bbox}`;
      fetch(url)
        .then(res => res.json())
        .then((res: FeatureCollection) => {
          this.buildRoad(res);
        });
    }
  }

  buildRoad(geojson: FeatureCollection): void {
    this.dataSource.load(geojson, {
      clampToGround: true,
    });

    this.dataSource.entities.values.forEach(road => {
      if (road.polyline) {
        road.polyline.material = new Cesium.PolylineOutlineMaterialProperty({
          color: Cesium.Color.RED,
          outlineColor: Cesium.Color.BLACK,
          outlineWidth: 1
        });
        road.polyline.width = new Cesium.ConstantProperty(10);
      }
    });
  }

  setActive(active: boolean): void {
    this.active = active;
    this.dataSource.show = active;
  }

  onAfterPrint(): void {
  }

  onBeforePrint(): void {
  }
}
