<div *ngFor="let layer of dynamicLayers; let i = index" class="pm-layer  mb-2 ">
  <div class="d-flex justify-content-between align-items-center">
    <b>{{layer.name}}</b>
    <div>
      <mat-slide-toggle (change)="handleLayerVisibility(layer)"
                        [checked]="layer.active">
      </mat-slide-toggle>
    </div>
  </div>
  <div *ngIf="layer.loadType === LayerLoadType.Manual" class="mt-1">
    <button class="pm-btn" (click)="layer.loadData()">
      <i class="bi bi-stack me-2"></i>
      Charger le calque
    </button>
  </div>

  <div *ngIf="layer.setLabelDistance" class="mt-1">
    <mat-form-field appearance="outline" class="w-100 mt-3">
      <mat-label>Distance entre les labels</mat-label>
      <input matInput type="number" [value]="layer.labelDistance" (change)="layer.setLabelDistance($event.target?.value)" required name="label_distance">
    </mat-form-field>
  </div>
</div>
